import { setToken, getToken, removeToken } from '../../utils/authStorage'

const state = {
  ws: null,
  wsToken: getToken() || '',
  msg: '',
  isSocket: false,
  pingTimer: null,
  num: 0
}

const mutations = {
  WEBSOCKET_INIT(state) {
    let url = process.env['VUE_APP_WSS']
    if (state.wsToken) {
      url = `${url}/im?token=${state.wsToken}`
    }
    if (state.ws) return
    const ws = new WebSocket(url)
    let that = this
    ws.onopen = () => {
      /*
       * 连接成功
       * */
      console.log('通讯开始')
      console.log(that)
      state.isSocket = true
      that.commit('ws/sendSockePing')
      // 发送心跳防止ws协议自动断联
      // setInterval(() => {
      //   ws.send(1)
      // }, 1000 * 60)
    }

    ws.onmessage = (msg) => {
      if (msg.data) {
        try {
          const data = JSON.parse(msg.data)
          console.log(data)
          state.msg = msg.data
          if (data.code === 401) {
            // removeToken()
            // state.ws = ''
            // state.token = ''
            // state.isSocket = false
          }
        } catch {
          //
        }
      }
    }

    ws.onerror = (err) => {
      console.log('错误', err)
      // that.commit('ws/reset')
    }
    ws.close = () => {
      console.log('关闭')
      // that.commit('ws/reset')
    }

    state.ws = ws
  },
  logoutMu(state) {
    clearTimeout(state.pingTimer)
    removeToken()
    state.ws.close()
    state.ws = ''
    state.wsToken = ''
    state.isSocket = false
  },
  quit(state) {
    state.ws.close()
  },
  reset(state) {
    clearTimeout(state.pingTimer)
    removeToken()
    state.ws = ''
    state.wsToken = ''
    state.isSocket = false
    this.commit('ws/WEBSOCKET_INIT')
  },
  sendSockePing() {
    const data = { action: 'ClientPing', data: '123', push: [] }
    console.log('发送心跳=========')
    this.commit('ws/sendSockeTask', JSON.stringify(data))
    // this.continuePing()
    this.commit('ws/continuePing')
  },
  continuePing(state) {
    state.pingTimer = setTimeout(() => {
      this.commit('ws/sendSockePing')
    }, 30000)
  },
  sendSockeTask(state, data) {
    state.ws.send(data)
  },
  SET_TOKEN(state, token) {
    state.wsToken = token
    setToken(token)
  }
}

const actions = {
  WEBSOCKET_INIT({ commit }, url) {
    commit('WEBSOCKET_INIT', url)
  },
  WEBSOCKET_SEND({ commit }, data) {
    commit('sendSockeTask', data)
  },
  setToken({ commit }, data) {
    commit('SET_TOKEN', data)
    commit('WEBSOCKET_INIT')
  },
  logout({ commit }) {
    commit('logoutMu')
  },
  quitApp({ commit }) {
    commit('quit')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
