import { apiRequest as request } from '../req'

/**
 * 组局列表
 * @params {Object}
 */
export function gatherList(params) {
  return request({
    url: '/gather/list',
    method: 'get',
    params
  })
}
/**
 * 生成登录二维码
 * @params {Object}
 */
export function createScanCode(params) {
  return request({
    url: '/venue/createCode',
    method: 'get',
    params
  })
}
/**
 * 生成登录二维码
 * @params {Object}
 */
export function scanCodeInfo(params) {
  return request({
    url: '/scan_code/info',
    method: 'get',
    params
  })
}

// 场地详情
export function getVenueInfo(params) {
  return request({
    url: '/venue/info',
    method: 'get',
    params
  })
}

// 收礼和送礼榜单
export function giftRank(params) {
  return request({
    url: '/log_send_gift/rank',
    method: 'GET',
    params
  })
}
