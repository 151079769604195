import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export default {
  install(Vue) {
    Vue.prototype.$day = Vue.day = dayjs
  }
}
export const day = dayjs
