import Vue from 'vue'
import {
  Button,
  Select,
  Message,
  Loading,
  Dialog,
  Tabs,
  TabPane
} from 'element-ui'

Vue.use(Button)
Vue.use(Select)
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(TabPane)

Vue.prototype.$loading = Loading.service

Vue.prototype.$message = Message
