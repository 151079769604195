import uuid from 'uuid/v4'

/**
 * 存储
 * try catch, 避免报错不执行代码!
 * localStorage
 * sessionStorage
 */
const localStorage = {
  setItem(key, value) {
    try {
      window.localStorage.setItem(key, value)
    } catch (e) {
      console.warn('utils/index.js localStorage.setItem', e)
    }
  },
  getItem(key) {
    try {
      return window.localStorage.getItem(key)
    } catch (e) {
      console.warn('utils/index.js localStorage.getItem', e)
    }
    return null
  },
  removeItem(key) {
    try {
      window.localStorage.removeItem(key)
    } catch (e) {
      console.warn('utils/index.js localStorage.removeItem', e)
    }
  }
}
const sessionStorage = {
  setItem(key, value) {
    try {
      window.sessionStorage.setItem(key, value)
    } catch (e) {
      console.warn('utils/index.js sessionStorage.setItem', e)
    }
  },
  getItem(key) {
    try {
      return window.sessionStorage.getItem(key)
    } catch (e) {
      console.warn('utils/index.js sessionStorage.getItem', e)
    }
    return null
  },
  removeItem(key) {
    try {
      window.sessionStorage.removeItem(key)
    } catch (e) {
      console.warn('utils/index.js sessionStorage.removeItem', e)
    }
  }
}

// 存储
export { localStorage, sessionStorage }

/**
 * base64ToBlob 转Blob文件
 * @param {String} base64 String
 * @returns {Object} Blob file
 */
export function base64ToFile({ name, base64URL }) {
  const arr = base64URL.split(',')
  const mime = arr[0].match(/:(.*?);/)[1] || 'image/png'
  const ext = /\/(\w+)/.exec(mime)[1] || ''
  // 去掉url的头，并转化为byte
  const bytes = window.atob(arr[1])
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length)
  // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
  const ia = new Uint8Array(ab)

  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  const blob = new Blob([ab], {
    type: mime
  })
  return new File([blob], name || `${uuid()}.${ext}`, {
    type: mime
  })
}

// Edited by Wendell Sheh
// 浮点型数值计算
// ## 加
/**
 ** 加法函数，用来得到精确的加法结果
 ** 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 ** 调用：accAdd(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accAdd(arg1, arg2) {
  var r1, r2, m, c
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  c = Math.abs(r1 - r2)
  m = Math.pow(10, Math.max(r1, r2))
  if (c > 0) {
    var cm = Math.pow(10, c)
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''))
      arg2 = Number(arg2.toString().replace('.', '')) * cm
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm
      arg2 = Number(arg2.toString().replace('.', ''))
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''))
    arg2 = Number(arg2.toString().replace('.', ''))
  }
  return (arg1 + arg2) / m
}

// ## 减
/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 调用：accSub(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accSub(arg1, arg2) {
  var r1, r2, m, n
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2)) // last modify by deeka
  // 动态控制精度长度
  n = r1 >= r2 ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

// ## 乘
/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
export function accMul(arg1, arg2) {
  let m = 0
  const s1 = arg1.toString()
  const s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) {
    // console.log('乘法函数 accMul:', e)
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
    // console.log('乘法函数 accMul:', e)
  }
  return (
    (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
    Math.pow(10, m)
  )
}

// ## 除
/**
 ** 除法函数，用来得到精确的除法结果
 ** 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
 ** 调用：accDiv(arg1,arg2)
 ** 返回值：arg1除以arg2的精确结果
 **/
export function accDiv(arg1, arg2) {
  let t1 = 0
  let t2 = 0
  try {
    t1 = arg1.toString().split('.')[1].length
  } catch (e) {
    //   console.log('除法函数 accMul:', e)
  }
  try {
    t2 = arg2.toString().split('.')[1].length
  } catch (e) {
    //   console.log('除法函数 accMul:', e)
  }
  const r1 = Number(arg1.toString().replace('.', ''))
  const r2 = Number(arg2.toString().replace('.', ''))
  return (r1 / r2) * Math.pow(10, t2 - t1)
}

export function copyObj(tar = {}) {
  return JSON.parse(JSON.stringify(tar))
}

/**
 * 深度复制对象
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * 移除字符串中间的空格
 * @param {String} str 处理的字符穿
 */
export function removeMidSpace(str = '') {
  return str.split(' ').join('')
}

/**
 * 解析 search 为 对象形式
 * @param {Object}} urlObj url 对象 , 如 location
 */
export function parseSearchToObject(urlObj) {
  const search = urlObj.search //获取url中"?"符后的字串
  const tar = {}
  if (search.indexOf('?') != -1) {
    const str = search.substr(1)
    const strs = str.split('&')
    for (var i = 0; i < strs.length; i++) {
      tar[strs[i].split('=')[0]] = strs[i].split('=')[1]
    }
  }
  return tar
}

/**
 * 获取元素相对根目录位置
 * @param {*} element
 * @return Object position
 */
export function getElemRootPos(element) {
  var x = 0
  var y = 0

  while (element) {
    x += element.offsetLeft - element.scrollLeft + element.clientLeft
    y += element.offsetTop - element.scrollLeft + element.clientTop
    element = element.offsetParent
  }

  return { x, y }
}

/**
 * 数组对象排序
 * @param array { Array } - 数据结构：[{}]
 * @param key { String }
 * @returns {*}
 */
export function sortByKey(array, key) {
  return array.sort(function (a, b) {
    const x = a[key]
    const y = b[key]
    return x < y ? -1 : x > y ? 1 : 0
  })
}

/**
 * 对象排序按ascii排序,且组装数据
 * @param obj { Object }
 * @returns { String } a=xxx&b=xxx
 */
export function asciiKeySortStr(obj) {
  let keyArr = Object.keys(obj)
  keyArr = keyArr.sort()
  return keyArr
    .map((item) => {
      return `${item}=${obj[item]}`
    })
    .join('&')
}
