<template>
  <div class="home">
    <!-- <div class="home-head">
      <div class="home-head-text">
        {{ venueInfo.name }} {{ time }} {{ week }}
      </div>
      <el-button v-if="!wsToken" class="home-login-but" @click="loginQr"
        >登录</el-button
      >
    </div> -->
    <img src="../assets/bg.png" class="home-bg" />
    <div class="home-box">
      <div class="home-box-info">
        <div class="home-box-left"></div>
        <!-- <vueSeamlessScroll
          ref="teamScroll"
          :data="teamList"
          :class-option="classOption"
          class="home-box-left"
        >
          <div class="home-box-team-list">
            <div
              v-for="(item, index) in teamList"
              :key="index"
              class="home-box-team-item"
            >
              <img
                :src="item.user.avatar_url"
                alt=""
                class="home-box-team-item-img"
              />
              <div class="home-box-team-item-info">
                <div class="home-box-team-item-name">
                  {{ item.name }}
                </div>
                <div class="home-box-team-item-title">
                  {{ item.dayFormat }}
                </div>
              </div>
            </div>
          </div>
        </vueSeamlessScroll> -->

        <div class="home-box-right">
          <img src="../assets/rankBg.png" class="home-box-rank-bg" />
          <div class="home-box-rank-list">
            <div class="home-box-rank-tab">
              <div
                v-for="(item, index) in rankTabList"
                :key="index"
                class="rank-tab-item"
                :class="{ 'rank-tab-item-select': index === rankActive }"
                @click="rankTabChange(index)"
              >
                {{ item }}
              </div>
            </div>

            <div class="home-box-rank-top">
              <div class="rank-second flex-center">
                <img src="../assets/rank2.png" class="rank-num-icon" />
                <img :src="rank2.avatar_url" class="rank-top-image" />
                <div class="rank-top-name">
                  {{ rank2.nickname || '暂无用户' }}
                </div>
                <div class="rank-top-coin">{{ rank2.score || 0 }}</div>
              </div>
              <div class="rank-first flex-center flex-center-first">
                <img src="../assets/rank1.png" class="rank-num-icon" />
                <img
                  :src="rank1.avatar_url"
                  class="rank-top-image rank-top-image-first"
                />
                <div class="rank-top-name rank-first-name">
                  {{ rank1.nickname || '暂无用户' }}
                </div>
                <div class="rank-top-coin">{{ rank1.score || 0 }}</div>
              </div>
              <div class="rank-third flex-center">
                <img src="../assets/rank3.png" class="rank-num-icon" />
                <img
                  :src="rank3.avatar_url"
                  class="rank-top-image rank-top-image-third"
                />
                <div class="rank-top-name">
                  {{ rank3.nickname || '暂无用户' }}
                </div>
                <div class="rank-top-coin">{{ rank3.score || 0 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="home-danmu"> -->
      <vue-danmaku
        ref="danmakuTeam"
        v-model="danmuTeams"
        use-slot
        :randomChannel="true"
        :speeds="150"
        class="home-team-danmu"
      >
        <template slot="dm" slot-scope="{ danmu }">
          <!-- <div class="home-danmu-item">
            <img :src="danmu.avatar_url" class="home-danmu-img" />
            <div class="home-danmu-name">{{ danmu.nickname }}:</div>
            <div class="home-danmu-text">{{ danmu.text }}</div>
          </div> -->
          <div class="barrage-box">
            <img
              :src="danmu.fish.headImg"
              class="barrage-head-img"
              :style="{ width: danmu.fish.headW, height: danmu.fish.headH }"
            />
            <div
              class="barrage-info-box"
              :class="[
                danmu.fishType === 'minnow' ? 'barrage-info-box-minnow ' : ''
              ]"
            >
              <div
                class="barrage-info-up"
                :style="{
                  background: danmu.fish.upBg,
                  color: danmu.fish.upColor,
                  height: danmu.fish.upBoxH,
                  lineHeight: danmu.fish.upBoxH
                }"
              >
                {{ danmu.name }}
              </div>
              <div
                class="barrage-info-down"
                :style="{
                  background: danmu.fish.downBg,
                  color: danmu.fish.downColor,
                  height: danmu.fish.downBoxH,
                  lineHeight: danmu.fish.downBoxH
                }"
              >
                {{ danmu.dayFormat }} 人数: {{ danmu.user_count || 0 }}/{{
                  danmu.max_user_count
                }}
              </div>

              <div
                class="barrage-box-up-img-box"
                :style="{ top: `-${danmu.fish.upH}`, height: danmu.fish.upH }"
              >
                <img
                  :src="danmu.fish.upImg"
                  :style="{ width: danmu.fish.upW, height: danmu.fish.upH }"
                />
              </div>
              <div
                class="barrage-box-down-img-box"
                :style="{
                  bottom: `-${danmu.fish.downH}`,
                  height: danmu.fish.downH
                }"
              >
                <img
                  :src="danmu.fish.downImg"
                  :style="{ width: danmu.fish.downW, height: danmu.fish.downH }"
                />
              </div>
            </div>
            <img
              v-if="danmu.fish.tailImg"
              :src="danmu.fish.tailImg"
              class="barrage-tail-img"
              :style="{ width: danmu.fish.tailW, height: danmu.fish.tailH }"
            />
          </div>
        </template>
      </vue-danmaku>

      <vue-danmaku
        ref="danmaku"
        v-model="danmus"
        use-slot
        :randomChannel="true"
        :speeds="200"
        class="home-danmu"
      >
        <!-- 弹幕插槽（vue 2.6.0 及以上版本可使用 v-slot:dm="{ index, danmu }"语法） -->
        <template slot="dm" slot-scope="{ danmu }">
          <div v-if="danmu.type === 'chat'" class="home-danmu-item">
            <img :src="danmu.avatar_url" class="home-danmu-img" />
            <div class="home-danmu-name">{{ danmu.nickname }}:</div>
            <div class="home-danmu-text">{{ danmu.text }}</div>
          </div>
          <div v-if="danmu.type === 'gift'" class="home-danmu-item">
            <img :src="danmu.avatar_url" class="home-danmu-img" />
            <!-- <div class="home-danmu-name">{{ danmu.nickname }}:</div> -->
            <div class="home-danmu-text">{{ danmu.text }}</div>
          </div>
        </template>
        <!-- 容器插槽 -->
        <div></div>
      </vue-danmaku>
      <!-- </div> -->

      <div class="home-foot">
        <el-button v-if="!wsToken" class="home-login-but" @click="loginQr"
          >登录</el-button
        >
      </div>

      <div class="home-foot-time">
        <div class="home-foot-time-box">
          <div class="home-foot-date">
            <div class="home-foot-year">{{ year }}</div>
            <div class="home-foot-md">{{ md }}</div>
          </div>
          <div class="home-foot-time-info">
            <div class="home-foot-week">{{ week }}</div>
            <div class="home-foot-time-ms">{{ time }}</div>
          </div>
        </div>
      </div>

      <div class="home-venue-qr">
        <vue-qr
          ref="qrCode"
          :text="textValue"
          class="home-venue-qr-img"
        ></vue-qr>

        <div class="home-venue-qr-text">扫码组局</div>
      </div>
    </div>

    <el-dialog
      title="登录"
      :modal-append-to-body="false"
      :append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <div class="login-box">
        <div class="login-title">扫码登录</div>
        <div class="login-qr">
          <vue-qr ref="qrCode" :text="loginValue" class="login-qr-img"></vue-qr>
        </div>
        <div class="login-text">管理员打开微信扫一扫登录</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import autofit from 'autofit.js'
import vueDanmaku from 'vue-danmaku'
// import vueSeamlessScroll from 'vue-seamless-scroll'
import { mapActions, mapGetters } from 'vuex'
// import VueDragResize from 'vue-drag-resize'
import VueQr from 'vue-qr'
import {
  gatherList,
  createScanCode,
  scanCodeInfo,
  getVenueInfo,
  giftRank
} from '@/api/team/index.js'
import { fish } from '@/utils/fish.js'
import { deepClone } from '@/utils/index.js'

export default {
  name: 'Home',
  components: { vueDanmaku, VueQr },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      page_size: 10,
      total_page: 1,
      rankList: [],
      teamList: [],
      danmus: [],
      danmuTeams: [],
      classOption: {
        // singleHeight: 120,
        hoverStop: false,
        step: 0.9
      },
      width: 302,
      height: 361,
      top: 300,
      left: 0,
      textValue: `${process.env['VUE_APP_API']}/venueHome?id=1`,
      venueId: 2,
      dialogVisible: false,
      loginValue: '',
      timer: null,
      teamTimer: null,
      teamData: [],
      venueInfo: {},
      secret_token: '',
      sign: '',
      damuBackList: [
        'rgba(255, 160, 37, 0.5)',
        'rgba(149, 162, 255, 0.5)',
        'rgba(173, 214, 60, 0.5)',
        'rgba(252, 179, 178, 0.5)'
      ],
      time: '',
      year: '',
      md: '',
      week: '',
      weekList: ['日', '一', '二', '三', '四', '五', '六'],
      rankTabList: ['送礼榜', '收礼榜', '活跃榜'],
      rankActive: 0,
      rank1: {},
      rank2: {},
      rank3: {},
      fishList: fish,
      rankTab: [
        {
          name: '送礼榜',
          rank1: {},
          rank2: {},
          rank3: {},
          key: 'first'
        },
        {
          name: '收礼榜',
          rank1: {},
          rank2: {},
          rank3: {},
          key: 'second'
        },
        {
          name: '活跃榜',
          rank1: {},
          rank2: {},
          rank3: {},
          key: 'third'
        }
      ],
      activeName: 'first',
      isFirst: true
    }
  },
  computed: {
    ...mapGetters(['wsToken', 'isSocket', 'msg'])
  },
  watch: {
    wsToken(val) {
      console.log(val)
    },
    isSocket(val) {
      console.log('socket连接', val)
      if (val) {
        this.joinVenue()
        this.sendRank()
      }
    },
    msg(val) {
      try {
        const msg = JSON.parse(val)
        if (msg.code === 401) {
          this['ws/logout']()
          this.$message.error(msg.message)
        }
        if (msg.action === 'VenueChatSendSuccessEvent') {
          this.updateChat(msg)
        }
        if (msg.action === 'UserSendGiftToOtherEvent') {
          this.updateGift(msg)
        }
        if (msg.action === 'ClientRankActiveUser') {
          this.getRankList(msg.data)
        }
      } catch {
        console.log(val)
      }
    }
  },
  created() {
    autofit.init({
      dh: 1080,
      dw: 1920,
      el: 'body',
      resize: true
    })
  },
  mounted() {
    this.$refs.danmaku.play()
    this.venueId = this.$route.query.venueId || this.$route.query.id || 1
    this.getInfo()
    setInterval(() => {
      this.getTime()
    }, 1000)
    this.getTeamList()
    this.getGiftRank()

    if (this.wsToken) {
      this['ws/WEBSOCKET_INIT']()
    }
  },
  beforeDestroy() {
    this['ws/quitApp']()
  },
  methods: {
    ...mapActions([
      'ws/WEBSOCKET_INIT',
      'ws/setToken',
      'ws/logout',
      'ws/WEBSOCKET_SEND',
      'ws/quitApp'
    ]),
    getRandom(max = 10, min = 0) {
      return Math.floor(Math.random() * (max - min) + min)
    },

    updateChat(data) {
      const obj = {
        avatar_url: data.data.avatar_url,
        nickname: data.data.nickname,
        type: 'chat',
        background: this.damuBackList[this.getRandom(this.damuBackList.length)],
        ...data.data
      }

      try {
        const msg = JSON.parse(data.message)
        if (msg.type) {
          if (msg.type === 'text') obj.text = msg.msg
          // if(msg.type === 'image') obj.image_url = msg.image_url
        } else {
          obj.text = data.message
        }
      } catch {
        obj.text = data.message
      }
      this.$refs.danmaku.insert(obj)
    },
    updateGift(data) {
      const obj = {
        avatar_url: data.user.avatar_url,
        nickname: data.user.nickname,
        type: 'gift',
        background: this.damuBackList[this.getRandom(this.damuBackList.length)],
        // ...data.data,
        text: data.data
      }

      // try {
      //   const msg = JSON.parse(data.message)
      //   if (msg.type) {
      //     if (msg.type === 'text') obj.text = msg.msg
      //     // if(msg.type === 'image') obj.image_url = msg.image_url
      //   } else {
      //     obj.text = data.message
      //   }
      // } catch {
      //   obj.text = data.message
      // }
      this.$refs.danmaku.insert(obj)
    },
    resize(newRect) {
      this.width = newRect.width
      this.height = newRect.height
      this.top = newRect.top
      this.left = newRect.left
    },
    getTimestamp() {
      return new Date().getTime()
    },
    async getTeamList() {
      clearInterval(this.teamTimer)
      try {
        const { data } = await gatherList({
          venue_id: this.venueId,
          page: 1,
          page_size: 99
        })

        let minnowIndex = 0
        let mediumIndex = 0
        let bigIndex = 0
        data.list = data.list
          .map((item) => {
            const day = this.$day(item.day_at).format('MM.DD')
            const week = `周${this.weekList[this.$day(item.day_at).day()]}`
            item.dayFormat = `${day} ${week} ${item.start_hour}:${
              item.start_minute || '00'
            }`

            const endDate = `${this.$day(item.day_at).format('YYYY/MM/DD')} ${
              item.end_hour
            }:${item.end_minute || '00'}`
            item.timestamp = new Date(endDate).getTime()

            if (item.max_user_count <= 4) {
              // 小鱼
              const fistList = this.fishList.minnow
              const i = minnowIndex % fistList.length
              item.fish = fistList[i]
              minnowIndex = minnowIndex + 1
              item.fishType = 'minnow'
            } else if (item.max_user_count > 4 && item.max_user_count < 9) {
              // 中鱼
              const fistList = this.fishList.medium
              const i = mediumIndex % fistList.length
              item.fish = fistList[i]
              mediumIndex = mediumIndex + 1
              item.fishType = 'medium'
            } else {
              // 大鱼
              const fistList = this.fishList.big
              const i = bigIndex % fistList.length
              item.fish = fistList[i]
              bigIndex = bigIndex + 1
              item.fishType = 'big'
            }

            return item
          })
          .filter((item) => {
            return item.timestamp > this.getTimestamp()
          })
        this.teamList = data.list

        this.timeSend()
        setTimeout(() => {
          this.getTeamList()
        }, 180000)
      } catch {
        setTimeout(() => {
          this.getTeamList()
        }, 180000)
      }
    },
    timeSend() {
      if (!this.teamList.length) return
      this.teamData = deepClone(this.teamList)
      clearInterval(this.teamTimer)
      this.teamTimer = setInterval(() => {
        // clearInterval(this.timer)
        if (this.isStop) {
          clearInterval(this.teamTimer)
          return
        }
        var talk = this.teamData.pop()
        if (talk) {
          // this.barrage.send(talk)
          if (talk.timestamp && talk.timestamp > this.getTimestamp()) {
            this.$refs.danmakuTeam.insert(talk)
          }
        } else {
          clearInterval(this.teamTimer)
          this.timeSend()
        }
      }, 1500)
    },
    async loginQr() {
      const { data } = await createScanCode({
        venue_id: this.venueId,
        code_type: 1
      })
      const info = data.data.data
      this.sign = info.sign
      this.secret_token = info.token
      this.loginValue = `${process.env['VUE_APP_API']}/autofit?code=${info.token}&sign=${info.sign}`
      this.dialogVisible = true
      this.checkState()
      // this.teamList = data.list
      console.log(data.data.data)
    },
    async checkState() {
      clearTimeout(this.timer)
      try {
        const { data } = await scanCodeInfo({
          code: this.secret_token,
          code_type: 1,
          sign: this.sign
        })
        if (data.status === 3) {
          this.dialogVisible = false
          this.$message({ message: '登录成功', type: 'success' })
          this['ws/setToken'](data.data.login_token)
          // this.getTicketList()
        } else if (data.status === 0) {
          // Toast('二维码失效,请重新出示')
          this.$message('二维码失效,请重试')
        } else {
          this.timeCheck()
        }
        console.log(data)
      } catch (err) {
        console.log(err)
        this.timeCheck()
      }
    },
    timeCheck() {
      if (!this.dialogVisible) {
        clearTimeout(this.timer)
        return
      }
      this.timer = setTimeout(() => {
        this.checkState()
      }, 3000)
    },
    async getInfo() {
      const { data } = await getVenueInfo({ venue_id: this.venueId })
      console.log(data)
      if (data.name) {
        this.venueInfo = data
        this.textValue = `${process.env['VUE_APP_API']}/venueHome?id=${data.id}&scene=1`
      } else {
        this.venueId = 1
        this.getInfo()
      }
    },
    joinVenue() {
      const data = {
        action: `ClientJoin_${this.venueId}`,
        data: [],
        push: { venue_id: this.venueId }
      }
      console.log(JSON.stringify(data))
      this['ws/WEBSOCKET_SEND'](JSON.stringify(data))
    },
    sendRank() {
      const data = {
        action: 'ClientRankActiveUser',
        data: [],
        push: { venue_id: this.venueId }
      }
      this['ws/WEBSOCKET_SEND'](JSON.stringify(data))
      setTimeout(() => {
        this.sendRank()
      }, 10000)
    },
    getRankList(list) {
      // this.rankList = list
      // this.rank1 = list[0] ? list[0] : []
      // this.rank2 = list[1] ? list[1] : []
      // this.rank3 = list[2] ? list[2] : []
      this.rankTab[2].rank1 = list[0] ? list[0] : []
      this.rankTab[2].rank2 = list[1] ? list[1] : []
      this.rankTab[2].rank3 = list[2] ? list[2] : []
    },
    getTime() {
      this.time = this.$day().format('HH:mm:ss')
      this.year = this.$day().format('YYYY')
      this.md = this.$day().format('MM.DD')
      this.week = `周${this.weekList[this.$day().day()]}`
    },
    async getGiftRank() {
      const [giveData, receiveData] = await Promise.all([
        giftRank({ venue_id: this.venueId, rank_type: 1, count: 3 }),
        giftRank({ venue_id: this.venueId, rank_type: 2, count: 3 })
      ])
      console.log(giveData, receiveData)
      const giveDataList = giveData.data.map((item) => {
        item.avatar_url = item.user.avatar_url
        item.nickname = item.user.nickname
        item.score = item.cost
        return item
      })
      const receiveDataList = receiveData.data.map((item) => {
        item.avatar_url = item.user.avatar_url
        item.nickname = item.user.nickname
        item.score = item.cost
        return item
      })
      this.rankTab[0].rank1 = giveDataList[0] ? giveDataList[0] : []
      this.rankTab[0].rank2 = giveDataList[1] ? giveDataList[1] : []
      this.rankTab[0].rank3 = giveDataList[2] ? giveDataList[2] : []
      this.rankTab[1].rank1 = receiveDataList[0] ? receiveDataList[0] : []
      this.rankTab[1].rank2 = receiveDataList[1] ? receiveDataList[1] : []
      this.rankTab[1].rank3 = receiveDataList[2] ? receiveDataList[2] : []

      if (this.isFirst) {
        this.isFirst = false
        this.getRankInfo()
        this.autoRankTabChange()
      }

      setTimeout(() => {
        this.getGiftRank()
      }, 10000)
    },
    getRankInfo() {
      this.rank1 = this.rankTab[this.rankActive].rank1
      this.rank2 = this.rankTab[this.rankActive].rank2
      this.rank3 = this.rankTab[this.rankActive].rank3
    },
    rankTabChange(index) {
      this.rankActive = index
      this.getRankInfo()
    },
    autoRankTabChange() {
      setTimeout(() => {
        if (this.rankActive === 2) {
          this.rankActive = 0
        } else {
          this.rankActive = this.rankActive + 1
        }
        this.getRankInfo()
        this.autoRankTabChange()
      }, 20000)
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-bg {
  position: absolute;
  bottom: 24px;
  // left: -21px;
  width: 100%;
  // height: 893px;
}

.home-head {
  height: 122px;
  background: #313fa1;
  display: flex;
  align-items: center;
  padding: 0 45px;
}
.home-head-text {
  font-size: 32px;
  font-weight: 500;
  line-height: 21px;
  color: #ffffff;
  flex: 1;
}
.home-box {
  flex: 1;
  position: relative;
  overflow: hidden;
}
.home-box-info {
  padding: 0 83px;
  display: flex;
  height: 100%;
}
.home-box-right {
  width: 580px;
  height: 340px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
}
.home-box-rank-bg {
  margin-top: 37px;
  width: 158px;
  height: 104px;
}
.home-box-left {
  overflow: hidden;
  margin-left: 67px;
  flex: 1;
}
.home-box-rank {
  font-size: 72px;
  text-align: center;
  display: flex;
  // align-items: center;
  letter-spacing: 0px;
  color: #ffffff;
}
.home-box-rank-list {
  width: 580px;
  // height: 340px;
  border-radius: 60px;
  background: #ffffff;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-box-rank-tab {
  // height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rank-tab-item {
  padding: 0 40px;
  font-size: 28px;
  text-align: center;
  color: #1a1a1a;
  position: relative;
}

.rank-tab-item-select {
  color: #0099ee;
  font-weight: bold;
}
.home-box-rank-top {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.rank-num-icon {
  width: 40px;
  height: 30px;
}

.rank-second {
  width: 200px;
  margin-top: 24px;
}

.rank-first {
  width: 200px;
  margin: 0 20px;
}

.rank-third {
  width: 200px;
  margin-top: 24px;
}

.flex-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.rank-top-image {
  width: 96px;
  height: 96px;
  background: rgba(127, 127, 127, 0.3);
  border-radius: 50%;
  border: 4px solid #dbdbdb;
}

.rank-top-image-first {
  width: 128px;
  height: 128px;
  border: 4px solid #fde100;
}
.rank-top-image-third {
  border: 4px solid #ffbc7d;
}

.rank-top-name {
  margin-top: 18px;
  font-size: 20px;
  color: #1a1a1a;
}

.rank-top-coin {
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold;
  color: #1a1a1a;
}
.rank-first-name {
  margin-top: 10px;
}

.m-t-16 {
  margin-top: 16px;
}
.m-t-6 {
  margin-top: 6px;
}
.home-box-rank-item {
  display: flex;
  align-items: center;
  margin-bottom: 55px;
}
.home-box-rank-item-num {
  font-size: 64px;
  text-align: center;
  display: flex;
  align-items: center;
  width: 110px;
  height: 96px;
  color: #ffffff;
}
.home-box-rank-item-img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.home-box-rank-item-name {
  margin-left: 30px;
  font-size: 64px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-box-team-item {
  // width: 100%;
  padding: 20px 30px 20px 20px;
  border-radius: 60px;
  background: #fff;
  margin-bottom: 20px;
  display: flex;
  width: max-content;
  // align-items: center;
  overflow: hidden;
  max-width: 1000px;
}
.home-box-team-item-img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.home-box-team-item-info {
  margin-left: 10px;
  flex: 1;
  overflow: hidden;
  // flex-grow: 1;
}
.home-box-team-item-name {
  font-size: 32px;
  // font-weight: 500;
  color: #3d3d3d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home-box-team-item-title {
  margin-top: 10px;
  font-size: 24px;
  // font-weight: 500;
  color: #aaaaaa;
}
.home-team-danmu {
  position: absolute;
  // bottom: 60px;
  top: 30px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  // margin-top: 60px;
}
.home-danmu {
  position: absolute;
  bottom: 60px;
  height: 50%;
  width: 100%;
}
.home-danmu-item {
  display: flex;
  align-items: center;
  padding: 10px 24px 10px 10px;
  background: #ffd400;
  border-radius: 35px;
}
.home-danmu-img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.home-danmu-name {
  margin-left: 10px;
  font-size: 21px;
  font-weight: 500;
  color: #1a1a1a;
}
.home-danmu-text {
  margin-left: 5px;
  font-size: 21px;
  font-weight: 500;
  color: #1a1a1a;
}
.home-venue-qr {
  position: absolute;
  bottom: 31px;
  // top: 338px;
  right: 26px;
  background: #fff;
  border-radius: 27px;
  border: 5px solid #000000;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 12;
  // cursor: move;
}
.home-venue-qr-img {
  width: 150px;
  height: 150px;
  background: #ffffff;
}
.home-venue-qr-text {
  // margin-top: 10px;
  font-weight: bold;
  font-size: 25px;
  color: #1a1a1a;
}
.home-venue-qr-name {
  margin-top: 16px;
  font-weight: 500;
  font-size: 40px;
  color: #ffa025;
}
.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login-title {
  color: #252933;
  font-size: 1.33rem;
  font-weight: 500;
  margin-bottom: 20px;
}
.login-qr {
  border: 1.5px solid #e4e6eb;
  border-radius: 4px;
  width: 222px;
  height: 222px;
}
.login-qr-img {
  width: 222px;
  height: 222px;
}
.login-text {
  margin-top: 30px;
  color: #515767;
}
.home-login-but {
  // background: rgba(30, 128, 255, 0.05);
  // color: #fff;
  width: 96px;
  height: 48px;
  background: #ffffff;
  color: #1a1a1a;
  font-weight: bold;
  font-size: 28px;
}
.home-foot {
  position: absolute;
  bottom: 0;
  height: 60px;
  background: #000000;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.home-foot-time {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 11;
  border-radius: 50px 0px 0px 0px;
  background: #000000;
  padding: 25px 236px 30px 30px;
  height: 136px;
}
.home-foot-date {
  display: flex;
  align-items: flex-end;
}
.home-foot-year {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}
.home-foot-md {
  margin-left: 10px;
  font-size: 50px;
  font-weight: bold;

  /* 纯白 */
  color: #ffffff;
}
.home-foot-time-info {
  display: flex;
  align-items: center;
}
.home-foot-week {
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
}
.home-foot-time-ms {
  margin-left: 13px;
  font-size: 31.5px;
  font-weight: bold;
  color: #ffffff;
}

.barrage-box {
  /* width: 470px; */
  /* height: 114px; */
  /* border-radius: 60px; */
  display: flex;
  align-items: center;
  position: relative;
  /* justify-content: center; */
  /* padding: 20px 30px 20px 20px; */
  /* background: #FFFFFF; */
}

.barrage-box-up-img-box {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}
.barrage-box-down-img-box {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.barrage-info-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  position: relative;
}

.barrage-info-box-minnow {
  overflow: hidden;
  border-radius: 10px;
}

.barrage-info-up {
  padding: 0 20px;
  font-size: 28px;
  height: 44px;
  line-height: 44px;
}
.barrage-info-down {
  padding: 0 20px;
  font-size: 28px;
  height: 36px;
  line-height: 36px;
}
</style>
