export const fish = {
  minnow: [
    {
      headImg: 'https://static.superyuwang.com/default/fish/minnow1.png',
      headW: '160px',
      headH: '120px',
      upBg: '#F56300',
      downBg: '#FFE5A2',
      upColor: '#FFFFFF',
      downColor: '#F56300',
      upBoxH: '44px',
      downBoxH: '36px'
    },
    {
      headImg: 'https://static.superyuwang.com/default/fish/minnow2.png',
      headW: '160px',
      headH: '120px',
      upBg: '#FF6F00',
      downBg: '#FFB74D',
      upColor: '#FFFFFF',
      downColor: '#3E4150',
      upBoxH: '44px',
      downBoxH: '36px'
    },
    {
      headImg: 'https://static.superyuwang.com/default/fish/minnow3.png',
      headW: '160px',
      headH: '120px',
      upBg: '#FF6F00',
      downBg: '#3E4150',
      upColor: '#FFFFFF',
      downColor: '#FFFFFF',
      upBoxH: '44px',
      downBoxH: '36px'
    },
    {
      headImg: 'https://static.superyuwang.com/default/fish/minnow4.png',
      headW: '160px',
      headH: '120px',
      upBg: '#FF9E0C',
      downBg: '#FFE45A',
      upColor: '#FFFFFF',
      downColor: '#E28800',
      upBoxH: '44px',
      downBoxH: '36px'
    },
    {
      headImg: 'https://static.superyuwang.com/default/fish/minnow5.png',
      headW: '160px',
      headH: '120px',
      upBg: '#2493B8',
      downBg: '#71E0FF',
      upColor: '#FFFFFF',
      downColor: '#2493B8',
      upBoxH: '44px',
      downBoxH: '36px'
    }
  ],
  medium: [
    {
      headImg: 'https://static.superyuwang.com/default/fish/mediumHead1.png',
      headW: '90px',
      headH: '80px',
      tailImg: 'https://static.superyuwang.com/default/fish/mediumTail1.png',
      tailW: '126px',
      tailH: '80px',
      upImg: 'https://static.superyuwang.com/default/fish/mediumUp1.png',
      upW: '112px',
      upH: '18px',
      downImg: 'https://static.superyuwang.com/default/fish/mediumDown1.png',
      downW: '33px',
      downH: '18px',
      upBg: '#F99F68',
      downBg: '#ffffff',
      upColor: '#FFFFFF',
      downColor: '#F99F68',
      upBoxH: '44px',
      downBoxH: '36px'
    },
    {
      headImg:
        'https://static.superyuwang.com/default/fish/mediumHead2.png?t=1',
      headW: '94px',
      headH: '80px',
      tailImg:
        'https://static.superyuwang.com/default/fish/mediumTail2.png?t=1',
      tailW: '144px',
      tailH: '88px',
      upImg: 'https://static.superyuwang.com/default/fish/mediumUp2.png',
      upW: '136px',
      upH: '44px',
      downImg: 'https://static.superyuwang.com/default/fish/mediumDown2.png',
      downW: '32px',
      downH: '10px',
      upBg: '#177DAA',
      downBg: '#C9ECFF',
      upColor: '#FFFFFF',
      downColor: '#177DAA',
      upBoxH: '44px',
      downBoxH: '36px'
    },
    {
      headImg: 'https://static.superyuwang.com/default/fish/mediumHead3.png',
      headW: '116px',
      headH: '88px',
      tailImg: 'https://static.superyuwang.com/default/fish/mediumTail3.png',
      tailW: '144px',
      tailH: '93px',
      upImg: 'https://static.superyuwang.com/default/fish/mediumUp3.png',
      upW: '88px',
      upH: '18px',
      downImg: 'https://static.superyuwang.com/default/fish/mediumDown3.png',
      downW: '42px',
      downH: '18px',
      upBg: '#E63724',
      downBg: '#CD1D0B',
      upColor: '#FFFFFF',
      downColor: '#FFFFFF',
      upBoxH: '44px',
      downBoxH: '36px'
    },
    {
      headImg:
        'https://static.superyuwang.com/default/fish/mediumHead4.png?t=1',
      headW: '192px',
      headH: '80px',
      tailImg: 'https://static.superyuwang.com/default/fish/mediumTail4.png',
      tailW: '160px',
      tailH: '116px',
      upImg: 'https://static.superyuwang.com/default/fish/mediumUp4.png',
      upW: '120px',
      upH: '40px',
      downImg: 'https://static.superyuwang.com/default/fish/mediumDown4.png',
      downW: '80px',
      downH: '18px',
      upBg: '#1F7ED1',
      downBg: '#EBF8FF',
      upColor: '#EBF8FF',
      downColor: '#1F7ED1',
      upBoxH: '44px',
      downBoxH: '36px'
    },
    {
      headImg: 'https://static.superyuwang.com/default/fish/mediumHead5.png',
      headW: '110px',
      headH: '80px',
      tailImg: 'https://static.superyuwang.com/default/fish/mediumTail5.png',
      tailW: '136px',
      tailH: '80px',
      upImg: 'https://static.superyuwang.com/default/fish/mediumUp5.png',
      upW: '168px',
      upH: '18px',
      downImg: 'https://static.superyuwang.com/default/fish/mediumDown5.png',
      downW: '144px',
      downH: '18px',
      upBg: '#E3EFFF',
      downBg: '#A8B8D7',
      upColor: '#1A1A1A',
      downColor: '#1A1A1A',
      upBoxH: '44px',
      downBoxH: '36px'
    }
    // {
    //   headImg: 'https://static.superyuwang.com/default/fish/mediumHead6.png',
    //   headW: '132px',
    //   headH: '102px',
    //   tailImg: 'https://static.superyuwang.com/default/fish/mediumTail6.png',
    //   tailW: '200px',
    //   tailH: '112px',
    //   upBg: '#C15D13',
    //   downBg: '#AB4700',
    //   upColor: '#FFFFFF',
    //   downColor: '#FFFFFF',
    //   upBoxH: '44px',
    //   downBoxH: '36px'
    // },
  ],
  big: [
    {
      headImg: 'https://static.superyuwang.com/default/fish/bigHead1.png?t=1',
      headW: '196px',
      headH: '120px',
      tailImg: 'https://static.superyuwang.com/default/fish/bigTail1.png?t=2',
      tailW: '364px',
      tailH: '216px',
      upImg: 'https://static.superyuwang.com/default/fish/bigUp1.png',
      upW: '114px',
      upH: '90px',
      downImg: 'https://static.superyuwang.com/default/fish/bigDown1.png',
      downW: '54px',
      downH: '78px',
      upBg: '#6C7B8E',
      downBg: '#DBDDEA',
      upColor: '#FFFFFF',
      downColor: '#6C7B8E',
      upBoxH: '66px',
      downBoxH: '54px'
    },
    {
      headImg: 'https://static.superyuwang.com/default/fish/bigHead2.png?t=1',
      headW: '152px',
      headH: '120px',
      tailImg: 'https://static.superyuwang.com/default/fish/bigTail2.png?t=1',
      tailW: '272px',
      tailH: '120px',
      upImg: 'https://static.superyuwang.com/default/fish/bigUp2.png',
      upW: '102px',
      upH: '60px',
      downImg: 'https://static.superyuwang.com/default/fish/bigDown2.png',
      downW: '66px',
      downH: '44px',
      upBg: '#3E4150',
      downBg: '#E8EFF8',
      upColor: '#FFFFFF',
      downColor: '#3E4150',
      upBoxH: '66px',
      downBoxH: '54px'
    },
    {
      headImg: 'https://static.superyuwang.com/default/fish/bigHead3.png?t=1',
      headW: '162px',
      headH: '120px',
      tailImg: 'https://static.superyuwang.com/default/fish/bigTail3.png?t=1',
      tailW: '288px',
      tailH: '120px',
      upImg: 'https://static.superyuwang.com/default/fish/bigUp3.png',
      upW: '75px',
      upH: '18px',
      downImg: 'https://static.superyuwang.com/default/fish/bigDown3.png',
      downW: '60px',
      downH: '27px',
      upBg: '#006CA5',
      downBg: '#99DCFF',
      upColor: '#FFFFFF',
      downColor: '#006CA5',
      upBoxH: '66px',
      downBoxH: '54px'
    }
  ]
}
