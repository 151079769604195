import axios from 'axios'
// import uuid from 'uuid/v4'
// import store from '@/store'
import route from '@/router'
import { Message } from 'element-ui'

import { TOKEN_EXPIRATION, NOT_SHOW_MSG } from './enum'

const Service = {
  baseURL: '',
  headers: {},
  timeout: 30e3,
  service: null,
  getService() {
    this.createAxios()
    this.defineRequest()
    this.defineResponse()
    return this.service
  },
  createAxios() {
    this.service = axios.create({
      // baseURL: process.env.BASE_API, // api 的 base_url
      baseURL: this.baseURL, // api 的 base_url www.xxx.com/proxy-api/
      // request timeout
      timeout: this.timeout,
      // 默认表单提交的方式
      headers: this.headers
    })
  },
  async defineRequestConfig(config) {
    let headers = {}
    // let headers = Object.assign(
    //   {
    //     'X-HEAD-PLATFORM': 'wap'
    //   },
    //   store.getters.headers
    // )
    // try {
    //   headers = await store.dispatch('GetHeaders')
    // } catch (res) {
    //   // 非手机环境会获取空头部参数
    //   // headers = res
    //   headers = store.getters.headers
    // }

    // 设置请求 token
    // 设置请求  requestId
    // let token = store.getters.token || ''
    // if (token) {
    //   Object.assign(headers, {
    //     Authorization: `Bearer ${token}`
    //   })
    // }
    // requestId
    // const reqid = uuid()
    // Object.assign(headers, {
    //   'X-HEAD-REQUEST-ID': reqid
    // })

    config.headers = headers
    return config
  },
  defineRequest() {
    this.service.interceptors.request.use(this.defineRequestConfig, (error) => {
      // Do something with request error
      Promise.reject(error)
    })
    //
    /* this.service.interceptors.request.use(config => {
      tokenRenew.pushRequestApi(config)
    }) */
  },
  defineResponseConfig(response) {
    const res = response.data
    if (res.code === 200) {
      return Promise.resolve(res)
    } else {
      return Promise.reject({ err: res })
    }
  },
  defineErrorResponseConfig({ err }) {
    console.log(err, 'err')
    if (err && err.code) {
      switch (true) {
        // 登录失效
        case TOKEN_EXPIRATION.includes(err.code):
          // 判断接口是否因为续订而导致token错误
          // Toast(err.msg || '网络错误，请稍后再试')
          Message({
            message: err.msg || '网络错误，请稍后再试',
            type: 'error',
            duration: 5 * 1000
          })
          route.replace({ path: '/login' })
          break
        // 不显示全局提示错误
        case NOT_SHOW_MSG.includes(err.code):
          break
        // token问题
        // case err.code === 108:
        //   Toast('请登录')
        //   window.location.replace('http://dido.thequn.com/m')
        //   break
        // 无网络
        case err.message === 'Network Error':
          Message({
            message: err.msg || '网络错误，请稍后再试',
            type: 'error',
            duration: 5 * 1000
          })
          // Toast(err.msg || '网络错误，请稍后再试')
          console.warn('Network Error', err)
          break
        // 全局错误提示
        default:
          Message({
            message: err.msg || '网络错误，请稍后再试',
            type: 'error',
            duration: 5 * 1000
          })
          // Toast(err.msg || '网络错误，请稍后再试')
          console.error(err.msg || 'Network Error, Please Try Again Latter!')
          break
      }
    }
    return Promise.reject(err)
  },
  defineResponse() {
    // 拦截器
    this.service.interceptors.response.use(this.defineResponseConfig.bind(this))
    this.service.interceptors.response.use(
      (res) => Promise.resolve(res),
      this.defineErrorResponseConfig.bind(this)
    )
  }
}

const apiService = Object.create(Service)
apiService.baseURL = process.env['VUE_APP_API']
// apiService.baseURL =
//   process.env.NODE_ENV == 'development' ? '/api' : process.env['VUE_APP_API']

export const apiRequest = apiService.getService()
