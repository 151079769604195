// 这里 localStorage 封装了一层捕获错误
import { localStorage } from './index'

// 登录态存储的 Token key
const TokenKey = 'Token'
const ENGINE_URL = 'ENGINE_URL'

export function getToken() {
  return localStorage.getItem(TokenKey) || ''
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  localStorage.removeItem(TokenKey)
}
export function getEngineUrl() {
  return localStorage.getItem(ENGINE_URL) || ''
}

export function setEngineUrl(url) {
  localStorage.setItem(ENGINE_URL, url)
}

export function delEngineUrl() {
  localStorage.removeItem(ENGINE_URL)
}
