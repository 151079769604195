<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import autofit from 'autofit.js'

export default {
  // mounted() {
  //   autofit.init({
  //     dh: 1080,
  //     dw: 1920,
  //     el: 'body',
  //     resize: true
  //   })
  // }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 16px;
  height: 100%;
  // background: #0f1327;
  // background: url('../src/assets/background.png') center center no-repeat;
  background: radial-gradient(
      55% 64% at 52% 0%,
      #42e6f5 0%,
      #40e0f4 20%,
      #3ddaf3 33%,
      #3ad2f2 46%,
      #31b9f0 73%,
      #269aed 100%
    ),
    #0099ee;
}
</style>
